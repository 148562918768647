import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import AuthService from '../../../services/AuthService';
import { useTranslation } from 'react-i18next';
import { Heading, HeadingSize } from '../../../components/shared/text/Heading';
import Button, { ButtonType } from '../../../components/shared/form-control/Button';
import { useCurrentClient } from '../../../global-state/Clients';

const SAMLRedirect = () => {
  const navigate = useNavigate();
  const params = useParams<{ clientId: string }>();
  const { t } = useTranslation('auth');
  const [searchParams] = useSearchParams();
  const [manualLogin, setManualLogin] = useState(false);
  const setCurrentClient = useCurrentClient((x) => x.setValue);

  useEffect(() => {
    setCurrentClient(null);
  }, [setCurrentClient]);

  const handleLogin = useCallback(() => {
    if (params.clientId) {
      AuthService.redirectWithSaml(params.clientId).then((response) => {
        if (response.data.redirectUrl) {
          window.location.href = decodeURI(response.data.redirectUrl);
        } else {
          navigate('/auth/login');
        }
      });
    }
  }, [navigate, params.clientId]);

  useEffect(() => {
    const shouldRedirect = searchParams.get('redirect') !== 'false';

    if (params.clientId && shouldRedirect) {
      handleLogin();
    } else {
      setManualLogin(true);
    }
  }, [handleLogin, navigate, params.clientId, searchParams]);

  return (
    <div className="text-center">
      {manualLogin ? (
        <>
          <Heading size={HeadingSize.H1}>{t('login.heading')}</Heading>
          <Heading size={HeadingSize.H2} actualSize={HeadingSize.H6}>
            {t('login.saml-subheading')}
          </Heading>
          <div className="px-10 text-center">
            <Button className="mt-6 w-full" type={ButtonType.PRIMARY} onClick={handleLogin} data-cy="saml-login-submit">
              <span className="font-medium">{t('login.buttons.saml-login')}</span>
            </Button>
            <div className="mt-4 text-center font-medium">
              <div className="mb-4">{t('login.or')}</div>
              <Button type={ButtonType.SECONDARY} onClick={() => navigate('/auth/login')} data-cy="password-login">
                {t('login.buttons.password-login')}
              </Button>
            </div>
          </div>
        </>
      ) : (
        <p>{t('sso.redirecting')}</p>
      )}
    </div>
  );
};

export default SAMLRedirect;
